import React, { createContext } from 'react';
import { useCheckAuth } from '../../shared/services/useCheckAuth';
import customerContextDefaultValues from './customerContextProvider.const';
import { CustomerContextState } from './customerContextProvider.types';

export const CustomerContext = createContext<CustomerContextState>(
  customerContextDefaultValues,
);

export const CustomerContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const { authStatus, token, loading } = useCheckAuth();
  console.log(loading, authStatus, token);
  if (loading) return null;

  return (
    <CustomerContext.Provider // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        token,
        authStatus,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};
