import {
  BookingContextState,
  BookingStepsContextState,
  SaveBookingStep,
} from './bookingContext.types';
import {
  BookingStepValueEnum,
  BookingSubStepOptionEnum,
  BookingSubStepValueEnum,
} from '../../components/BookingWidget/bookingSteps.interface';
import { BookingDataEnum } from '../../components/BookingWidget/BookingManagment/bookingManagment.interface';

export const contextDefaultValues: BookingContextState = {
  bookingSteps: {
    [BookingStepValueEnum.CurrentStep]: BookingStepValueEnum.QuotationForm,
    [BookingStepValueEnum.Partner]: '',
    [BookingStepValueEnum.AggregatorId]: '',
    [BookingStepValueEnum.AffiliateId]: '',
    [BookingStepValueEnum.QuotationPreliminaryDeclarations]: null!,
    [BookingStepValueEnum.QuotationForm]: {
      [BookingSubStepValueEnum.TripDetails]: {
        [BookingSubStepOptionEnum.Value]: '',
        [BookingSubStepOptionEnum.Label]: '',
      },
      [BookingSubStepValueEnum.MaxTripDuration]: {
        [BookingSubStepOptionEnum.Value]: '',
        [BookingSubStepOptionEnum.Label]: '',
      },
      [BookingSubStepValueEnum.GeographicalZone]: {
        [BookingSubStepOptionEnum.Value]: '',
        [BookingSubStepOptionEnum.Label]: '',
      },
      [BookingSubStepValueEnum.DepartureDate]: '',
      [BookingSubStepValueEnum.ReturnDate]: '',
      [BookingSubStepValueEnum.TravellersType]: null!,
      [BookingSubStepValueEnum.TravellersAge]: [''],
      [BookingSubStepValueEnum.PromoCode]: '',
    },
    [BookingStepValueEnum.QuotationProposals]: {
      [BookingSubStepValueEnum.TotalPrice]: 0,
      [BookingSubStepValueEnum.Proposal]: null!,
      [BookingSubStepValueEnum.Tables]: '',
      [BookingSubStepValueEnum.Addons]: {},
      [BookingSubStepValueEnum.Options]: {},
    },
    [BookingStepValueEnum.QuotationInformation]: {
      [BookingSubStepValueEnum.InformationPersonal]: null!,
      [BookingSubStepValueEnum.InformationMedical]: null!,
      [BookingSubStepValueEnum.InformationTravellers]: null!,
      [BookingSubStepValueEnum.MedicalScreening]: [],
    },
    [BookingStepValueEnum.QuotationRecapDeclaration]: null!,
    [BookingStepValueEnum.ExtraFields]: {
      [BookingDataEnum.ExternalBookingId]: '',
      [BookingDataEnum.ExternalBacklink]: '',
    },
  },
  update: (): Promise<BookingStepsContextState> =>
    new Promise((resolve) => {
      resolve({} as BookingStepsContextState);
    }),
  updateAndSaveOneStep: (): Promise<SaveBookingStep | null> =>
    new Promise((resolve) => {
      resolve({} as any);
    }),
  updateDefault: (): void => undefined,
  loadQuote: (): void => undefined,
  loadBooking: (): void => undefined,
};

export default contextDefaultValues;
