import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { AgentConfigContextState } from './agentConfigContext.types';
import {
  AgentConfig,
  AgentConfigScript,
} from '../../components/BookingWidget/bookingSteps.interface';
import { CustomerContext } from '../customerContextProvider/CustomerContextProvider';

const contextDefaultValues: AgentConfigContextState = {
  agentConfigData: undefined,
  updateConfig: (): void => undefined,
  getScript: (): AgentConfigScript | undefined => undefined,
  isAgentView: false,
};
export const AgentConfigContext =
  createContext<AgentConfigContextState>(contextDefaultValues);

export const AgentConfigContextProvider: React.FC<
  React.PropsWithChildren<object>
> = ({ children }) => {
  const [agentConfigData, setAgentConfig] = useState<AgentConfig | undefined>(
    contextDefaultValues.agentConfigData,
  );
  const [isAgentView, setIsAgentView] = useState<boolean>(
    contextDefaultValues.isAgentView,
  );
  const { authStatus } = useContext(CustomerContext);
  const handleUpdate = (agentConfig: AgentConfig): void =>
    setAgentConfig(agentConfig);
  const getScript = (
    step: string,
    substep?: string,
  ): AgentConfigScript | undefined =>
    agentConfigData?.scripts.find(
      (script) => script.step === step && script.substep === substep,
    );

  useEffect((): void => {
    const agent: boolean = authStatus.isAgent;
    if (agent) {
      setIsAgentView(true);
      // setAgentConfig(bookingConfigInit.agentConfig);
    }
  }, []);

  const agentConfig: AgentConfigContextState = useMemo(
    () => ({
      agentConfigData,
      updateConfig: handleUpdate,
      getScript,
      isAgentView,
    }),
    [agentConfigData, isAgentView],
  );

  return (
    <AgentConfigContext.Provider value={agentConfig}>
      {children}
    </AgentConfigContext.Provider>
  );
};
