import axios from 'axios';
import {
  BookingConfig,
  BookingConfigEnum,
} from '../../components/BookingWidget/bookingSteps.interface';

const useApi = (bookingConfigData: BookingConfig): any => {
  const apiCM360Url: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Endpoint;
  const apiCM360Key: string =
    bookingConfigData[BookingConfigEnum.DataCenter]?.cm360Key;
  const api = axios.create({
    baseURL: apiCM360Url,
  });
  api.interceptors.request.use(
    async (config) => {
      const modifiedConfig = { ...config };

      if (!modifiedConfig.headers['X-Api-Key']) {
        modifiedConfig.headers['X-Api-Key'] = apiCM360Key;
      }

      modifiedConfig.headers['Content-Type'] = 'application/json';

      if (modifiedConfig.withCredentials === undefined) {
        modifiedConfig.withCredentials = true;
      }

      return modifiedConfig;
    },
    (error) => Promise.reject(error),
  );

  return api;
};

export default useApi;
