import { AxiosResponse } from 'axios';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { useState, useEffect, useContext } from 'react';
import { BookingConfigEnum } from '../../components/BookingWidget/bookingSteps.interface';
import { BookingConfigContext } from '../../context/bookingConfigContext/bookingConfigContext';
import useApi from './api.service';
import getUrlSearchParam from '../helpers/urlSearchParams.helper';

export interface CustomJwtPayload extends JwtPayload {
  source?: string;
  email?: string;
  iss?: string;
}

export interface CheckAuthHook {
  token: CustomJwtPayload | null;
  authStatus: AuthStatus;
  loading: boolean;
}

export interface AuthStatus {
  isAgent: boolean;
  isAuthCustomer: boolean;
  isAnonCustomer: boolean;
}

const useCheckAuth = (): CheckAuthHook => {
  const { bookingConfigData } = useContext(BookingConfigContext);
  const [loading, setLoading] = useState(true);
  const API = useApi(bookingConfigData);
  const [token, setToken] = useState<CustomJwtPayload | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>({
    isAgent: false,
    isAnonCustomer: false,
    isAuthCustomer: false,
  });
  const urlParams: URLSearchParams = new URLSearchParams(
    window.location.search,
  );
  const paramsToken: string | null = getUrlSearchParam('token', urlParams);

  useEffect(() => {
    setLoading(true);
    const getToken = async (): Promise<void> => {
      if (!token) {
        const apiCM360Url: string =
          bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint;
        const url = `${apiCM360Url}/get-token`;
        const response: AxiosResponse = await API.get(url);
        setLoading(false);
        setToken(response.data.token ? jwtDecode(response.data.token) : null);
      }
    };

    const verifyToken = async (): Promise<void> => {
      if (!token) {
        const apiCM360Url: string =
          bookingConfigData[BookingConfigEnum.DataCenter].cm360Endpoint;
        const url = `${apiCM360Url}/verify-token`;
        await API.post(
          url,
          {},
          {
            headers: {
              Authorization: `Bearer ${paramsToken}`,
            },
          },
        ).then(() => {
          setToken(paramsToken ? jwtDecode(paramsToken) : null);
          setLoading(false);
        });
      }
    };

    if (paramsToken) {
      verifyToken();
    } else {
      getToken();
    }
  }, []);

  const isAuthCustomer = (): boolean => {
    if (!token) {
      return false;
    }
    return token.iss?.includes('cognito') ?? false;
  };

  const isAnonCustomer = (): boolean => {
    if (!token) {
      return false;
    }
    return token?.source === 'CM';
  };

  const isAgent = (): boolean => {
    if (!token) {
      return false;
    }
    const isAuth = isAuthCustomer();
    const isAnon = isAnonCustomer();
    return !isAuth && !isAnon;
  };

  useEffect(() => {
    const checkAuthStatus = (): void => {
      const isAgentStatus = isAgent();
      const isAuthCustomerStatus = isAuthCustomer();
      const isAnonCustomerStatus = isAnonCustomer();

      setAuthStatus({
        isAgent: isAgentStatus,
        isAuthCustomer: isAuthCustomerStatus,
        isAnonCustomer: isAnonCustomerStatus,
      });
    };

    checkAuthStatus();
  }, [token]);

  return {
    authStatus,
    loading,
    token,
  };
};

export { useCheckAuth };
