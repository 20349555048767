import React, { useContext } from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFetchBookingConf from '../../components/BookingWidget/BookingConfig/useFetchBookingConf';
import { BookingConfig } from '../../components/BookingWidget/bookingSteps.interface';
import { LoadingContext } from '../loadingContext/loadingContext';

const LoadBookingConfig: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const { setIsLoading } = useContext(LoadingContext);
  const { t } = useTranslation();
  const bookingParams = '';
  const { bookingConfig, error } = useFetchBookingConf<BookingConfig>(
    bookingParams,
    setIsLoading,
  );

  if (error) {
    return (
      <Alert className="error-loading" severity="error">
        {t('COMMON.errorLoading')}
      </Alert>
    );
  }
  if (!bookingConfig) {
    return null;
  }

  return children;
};

export default LoadBookingConfig;
