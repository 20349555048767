import React from 'react';
import './App.scss';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import { CssBaseline } from '@mui/material';
import BookingWidget from './components/BookingWidget/BookingWidget';
import { BookingConfigContextProvider } from './context/bookingConfigContext/bookingConfigContext';
import { BookingContextProvider } from './context/bookingContext/bookingContext';
import Spinner from './shared/components/Loading/Spinner';
import { LoadingProvider } from './context/loadingContext/loadingContext';
import { NotificationProvider } from './shared/components/Notification/NotificationContext';
import { AgentConfigContextProvider } from './context/agentContext/agentConfigContext';
import { BookingQuoteContextProvider } from './context/quoteContext/quoteContext';
import { BookingDataContextProvider } from './context/bookingDataContext/bookingDataContext';
import '@fontsource/roboto';
import { AppConfigurationContextProvider } from './context/appConfigurationContext/appConfigurationContext';
import LoadBookingConfig from './context/bookingContext/LoadBookingConfig';
import { CustomerContextProvider } from './context/customerContextProvider/CustomerContextProvider';
import CustomThemeProvider from './context/customThemeProvider/CustomThemeProvider';

function App(): JSX.Element {
  LicenseInfo.setLicenseKey(
    '91fe376c8a66f0af99843dea3b0bb39eTz05OTYzNyxFPTE3NjAwODY5NjAwMDAsUz1wcm8sTE09cGVycGV0dWFsLFBWPVEzLTIwMjQsS1Y9Mg==',
  );
  return (
    <LoadingProvider>
      <BookingConfigContextProvider>
        <BookingQuoteContextProvider>
          <BookingDataContextProvider>
            <LoadBookingConfig>
              <CustomerContextProvider>
                <NotificationProvider>
                  <BookingContextProvider>
                    <AgentConfigContextProvider>
                      <AppConfigurationContextProvider>
                        <CustomThemeProvider>
                          <CssBaseline enableColorScheme />
                          <Spinner />
                          <div className="App">
                            <BookingWidget />
                          </div>
                        </CustomThemeProvider>
                      </AppConfigurationContextProvider>
                    </AgentConfigContextProvider>
                  </BookingContextProvider>
                </NotificationProvider>
              </CustomerContextProvider>
            </LoadBookingConfig>
          </BookingDataContextProvider>
        </BookingQuoteContextProvider>
      </BookingConfigContextProvider>
    </LoadingProvider>
  );
}

export default App;
