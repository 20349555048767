import { Theme, ThemeProvider } from '@emotion/react';
import React, { useContext, useEffect, useState } from 'react';
import getUrlSearchParam from '../../shared/helpers/urlSearchParams.helper';
import { BookingStylesEnum } from '../../shared/interfaces/bookingStyles.interface';
import useFetchBookingStyles from '../../shared/services/useFetchStylesConf';
import createAppTheme, { createAppThemeAPI } from '../../themes/theme';
import defaultBrand from '../../themes/brand-default.scss';
import { CustomerContext } from '../customerContextProvider/CustomerContextProvider';

export const ThemeContext = React.createContext(null);

const setStyleColors = (colors: any): void => {
  const root = document.documentElement;
  root.style.setProperty('--primary', colors.default.primary);
  root.style.setProperty('--text-primary', colors.default.textPrimary);
  root.style.setProperty('--dark-primary', colors.default.darkPrimary);
  root.style.setProperty('--dark-middle', colors.default.darkMiddle);
  root.style.setProperty('--dark-secondary', colors.default.darkSecondary);
  root.style.setProperty('--light-primary', colors.default.lightPrimary);
  root.style.setProperty('--light-secondary', colors.default.lightSecondary);
  root.style.setProperty('--contrast-primary', colors.default.contrastPrimary);
  root.style.setProperty('--selected-card', colors.default.selectedCard);
  root.style.setProperty(
    '--contrast-secondary',
    colors.default.contrastSecondary,
  );
};
const setStyleColorsApi = (colors: any): void => {
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value as string);
  });
};

const setFontStyle = (brand: string): void => {
  import(`../../themes/fonts/${brand}/fonts.scss`);
};

const CustomThemeProvider: React.FC<React.PropsWithChildren<object>> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(createAppTheme(defaultBrand, ''));
  const { authStatus } = useContext(CustomerContext);
  const { styleConfig } = useFetchBookingStyles();
  useEffect((): void => {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );
    const brand: string | null = getUrlSearchParam('brand', urlParams);
    localStorage.setItem('language', 'en');
    if (brand && styleConfig && !authStatus.isAgent) {
      setFontStyle(brand);
      setTheme(
        createAppThemeAPI(
          styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
          brand,
        ),
      );
      setStyleColorsApi(
        styleConfig[BookingStylesEnum.Styles][BookingStylesEnum.Colors],
      );
    } else {
      import('../../themes/brand-default.scss').then((colors): void => {
        setTheme(createAppTheme(colors.default, ''));
        setStyleColors(colors);
      });
    }
  }, [styleConfig]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
