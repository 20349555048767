import { CustomerContextState } from './customerContextProvider.types';

const customerContextDefaultValues: CustomerContextState = {
  token: null,
  authStatus: {
    isAgent: false,
    isAuthCustomer: false,
    isAnonCustomer: true,
  },
};

export default customerContextDefaultValues;
